@import url("https://fonts.googleapis.com/css2?family=Suez+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");

body {
  margin: 0px;
  font-family: "Comfortaa";
  cursor: pointer;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.MuiButtonBase-root {
  font-family: "Comfortaa" !important;
}

/* For Iphone */

*:not(html) {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.recharts-tooltip-wrapper {
  .customTooltip {
    p {
      margin: 0;
    }
  }
}

.css-b62m3t-container {
  transform: initial !important;
}

.Toastify {
  transform: unset !important;
}
