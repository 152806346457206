.grammar_text_area {
  padding: 15px 5px;
  padding-right: 0px;
  width: 100%;
  font-size: 18px;
  border-radius: 15px;
}

.auto-adjustable-textarea {
  resize: none;
  overflow: hidden;
  /* Hide vertical scrollbar */
}

.main_con {
  display: flex;
  justify-content: center;
}

.main_buttons {
  margin: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.grammar_buttons {
  text-transform: capitalize !important;
  padding: 10px 25px !important;
  border-radius: 15px !important;
  color: black !important;
  border: 1px solid black !important;
}

.filter_button {
  text-transform: capitalize !important;
  padding: 10px 50px !important;
  border-radius: 15px !important;
  color: black !important;
  border: 1px solid black !important;
}

.modal_grammar_card {
  position: absolute;
  height: 100vh;
  width: 70%;
  z-index: 1;
  overflow: scroll !important;
}

.slideIn {
  opacity: 1;
  -webkit-animation: slide 1.5s linear forwards;
  animation: slide 1.5s linear forwards;
}

.slideOut {
  display: none;
}

@keyframes slide {
  0% {
    right: -100%;
  }

  25% {
    right: -75%;
  }

  50% {
    right: -50%;
  }

  75% {
    right: -25%;
  }

  100% {
    right: 0%;
  }
}
