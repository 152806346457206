.grid_of_three {
  display: grid;
  grid-template-columns: 26% 34% 40%;
  margin-bottom: 2vh;
}
.grid_of_four {
  display: grid;
  grid-template-columns: 25% 35% 20% 20%;
  margin-bottom: 2vh;
}
.grid_of_two {
  display: grid;
  grid-template-columns: 40% 60%;
  margin-bottom: 2vh;
}

@media screen and (max-width: 1000px) {
  .grid_of_three,
  .grid_of_four,
  .secondgrid_of_three,
  .grid_of_two {
    display: block !important;
  }
  .text_value {
    display: block !important;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .grid_of_three,
  .grid_of_four,
  .secondgrid_of_three {
    grid-template-columns: 50% 50% !important;
  }
  .text_value {
    margin-bottom: 2vh;
  }
  .grid_of_two {
    display: block !important;
  }
}

.text_value {
  display: flex;
  gap: 10px;
  align-items: center;
}

.appuserdetailscard {
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.161)) !important;
  -webkit-filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.161)) !important;
  /* white-space:nowrap !important; */

  margin: 25px;
}

.secondgrid_of_three {
  display: grid;
  grid-template-columns: 40% 40% 20%;
  margin-bottom: 2vh;
}

@media screen and (max-width: 1000) {
  .desktop_edit_tab {
    display: none !important;
  }
}
