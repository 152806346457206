.card {
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.161)) !important;
  -webkit-filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.161)) !important;
  /* white-space:nowrap !important; */
  overflow: scroll !important;
  -webkit-overflow-scrolling: touch !important;
  height: 95vh !important;
  margin: 25px;
  width: 100%;
  overflow-x: scroll !important;
}

@media screen and (max-width: 750px) {
  .card {
    padding: 0 !important;
    /* height: 100% !important;
    overflow: auto !important; */
    display: block;
    height: 100vh;
    overflow-y: scroll;
    margin: 0px !important;
    transform: none !important;
  }
}

.cardview {
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.161)) !important;
  -webkit-filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.161)) !important;
  /* white-space:nowrap !important; */
  overflow: scroll !important;
  -webkit-overflow-scrolling: touch !important;
  margin: 25px;
  max-height: 60vh !important;
}

.griding {
  display: grid;
  grid-template-columns: 2fr 1fr 1.3fr;
  grid-template-areas: " a b c";
}

.gridingstored {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-areas: " a c";
}
.bottomhalf {
  display: grid;
  grid-template-columns: 19% 20% 19% 19% 19% 5%;
  grid-template-areas: " a1 b1 c1 d1 e1 f1";
  margin-top: 5px;
}

.category {
  grid-area: e1;
  text-align: center;
}

.totalnoti {
  grid-area: a1;
  text-align: center;
}
.succesnoti {
  grid-area: b1;
  text-align: center;
}
.userinterac {
  grid-area: c1;
  text-align: center;
}
.enddatetime {
  grid-area: d1;
  text-align: center;
}

.duplicate {
  position: absolute;
  top: 35%;
  left: 95%;
}

.first {
  grid-area: a;
  display: flex;
  align-items: center;
  gap: 10px;
}
.second {
  grid-area: b;
}
.third {
  grid-area: c;
}

/* .card {
  overflow: scroll;
}
.card::-webkit-scrollbar {
  width: 12px;
}

.card::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
} */

.cardsub {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.161)) !important;
  -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.161)) !important;
  width: 100%;
  /* overflow: scroll !important; */
  /* padding: 15px; */
}

.react-select-6-listbox {
  z-index: 2 !important;
}

.notidistext {
  width: 100% !important;
  border-radius: 25px !important;
}

.title {
  font-family: "Suez One", serif !important;
  font-size: 14px !important;
  line-height: 1.4 !important;
}

.body {
  font-family: "Nunito Sans", sans-serif !important;
  font-style: normal;
  font-size: 13px !important;
  line-height: 1.4 !important;
  line-break: anywhere !important;
}

.noticards {
  padding: 8px !important;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.duplicate_icon {
  font-size: 25px;
}

@media screen and (min-width: 750px) and (max-width: 850px) {
  .resp {
    font-size: 10px !important;
  }
  .duplicate_icon {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 534px) and (min-width: 390px) {
  .resp {
    font-size: 10px !important;
  }
  .duplicate_icon {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 389px) {
  .resp {
    font-size: 8px !important;
  }
  .duplicate_icon {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1150px) {
  .duplicate {
    left: 85%;
  }
}
