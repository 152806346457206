.teacherImage {
  width: 200px;
  height: 200px;
  object-fit: contain;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

.duration-input {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.duration-input input {
  width: 50px;
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
}

.duration-input span {
  margin-right: 5px;
}

/* Media query for smaller devices (e.g., smartphones) */
@media screen and (max-width: 767px) {
  .teacherImage {
    width: 120px;
    height: 120px;
  }
}

/* Media query for medium-sized devices (e.g., tablets) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .teacherImage {
    width: 150px;
    height: 150px;
  }
}

/* Media query for larger devices (e.g., laptops and desktops) */
@media screen and (min-width: 1024px) {
  .teacherImage {
    width: 200px;
    height: 200px;
  }
}

.teacherCard {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.161)) !important;
  -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.161)) !important;
  width: 90%;
  border-radius: 20px !important;
  margin: 20px 10px;
}

.classes_first_sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  text-align: center;
  align-items: flex-start;
}

.classes_second_sec {
  margin-top: 10px;
  text-align: left;
}

.classes_time_duration {
  margin-top: 10px;
  text-align: left;
}

.teacherButtons {
  display: flex !important;
  gap: 10px !important;
  border-radius: 10px !important;
  align-items: center !important;
  text-transform: capitalize !important;
}

.teachertitle {
  font-weight: bold !important;
  font-size: 20px !important;
}

@media screen and (max-width: 450px) {
  .teacherDetails {
    gap: 10px !important;
  }
}

.detailcard {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
}

/* Classes Toggle Start Class Button */
.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 58px;
  height: 32px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggle-switch:before,
.toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-checkbox:checked+.toggle-switch {
  background: #56c080;
}

.toggle-checkbox:checked+.toggle-switch:before {
  left: 30px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}



.grpingImage {
  width: 350px;
  height: 350px;
  object-fit: contain;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}