.appbar {
  background-color: #051e34 !important;
  color: white !important;
}

.navbar {
  display: none;
  position: sticky;
  top: 0;
  z-index: 5;
}

@media screen and (max-width: 750px) {
  .navbar {
    display: block;
  }
}
