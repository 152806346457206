.errorgrid {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "first second third";
}

.firstgrid {
  grid-area: first;
  display: flex;
  gap: 10px;
  align-items: center;
}
.secondgrid {
  grid-area: second;
  display: flex;
  gap: 10px;
  align-items: center;
}
.thirdgrid {
  grid-area: third;
  display: flex;
  gap: 10px;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .errorgrid {
    display: block;
  }
  .firstgrid,
  .secondgrid,
  .thirdgrid {
    justify-content: center;
  }
}
