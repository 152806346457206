.payment_data {
  display: flex;
  align-items: center;
  gap: 13px;
  flex-wrap: wrap;
}

@media screen and (max-width: 450px) {
  .payment_data {
    display: block;
  }
}
