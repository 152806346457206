.feedbackcontrolfirstgrid {
  display: grid;
  grid-template-columns: 33% 34% 33%;
  margin-bottom: 15px;
}
.feedbackcontrolsecondgrid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .feedbackcontrolfirstgrid,
  .feedbackcontrolsecondgrid {
    display: block;
    text-align: center;
  }
}

.tabsfeedback {
  text-transform: capitalize !important;
}

.invisible {
  opacity: 0.2;
}

.titles {
  font-size: 25px !important;
  font-family: "Suez One", serif !important;
}

.titles2 {
  font-size: 20px !important;
  font-family: "Suez One", serif !important;
}

.bodys {
  font-family: "Nunito Sans", sans-serif !important;
  font-style: normal;
  font-size: 13px !important;
  line-height: 0.8 !important;
}

.gridings {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: " a b c";
  text-align: center;
}

.bottomoptions {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "a1  b1";
  text-align: center;
  margin-top: 15px;
  gap: 3vw;
}

.suggestionsoptions {
  text-align: center;
}
.rawview {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "a2  b2";
  text-align: center;
}

.rawview1 {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "a3 c3 b3";
  text-align: center;
}

.uidheadings {
  display: flex;
  grid-area: a2;
  gap: 10px;
}
.dateview {
  grid-area: b2;
}

.uidheadings1 {
  grid-area: a3;
}
.platform {
  grid-area: b3;
}
.dateview1 {
  grid-area: c3;
}

@media screen and (max-width: 900px) {
  .bottomoptions {
    display: block !important;
  }
  .options {
    margin-bottom: 3vh;
  }
}

.yesno {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-areas: "a1 c1 b1";
  text-align: center;
  margin-top: 15px;
}
.yes {
  grid-area: a1;
}
.no {
  grid-area: b1;
}

.firsts {
  grid-area: a;
}
.seconds {
  grid-area: b;
}
.thirds {
  grid-area: c;
}

.progresslabel {
  color: white;
}

@media screen and (max-width: 750px) {
  .titles2,
  .titles {
    font-size: 14px !important;
  }
  .gridings {
    display: block !important;
  }
  .firsts {
    margin-bottom: 10px !important;
  }
  .progresslabel {
    font-size: 8px;
  }
  .progwrapper {
    margin-top: 5px;
  }
  .rawview {
    display: block !important;
  }
}
