.cashBookgriding {
  display: grid;
  grid-template-columns: 35% 45% 30%;
  margin-bottom: 1vh;
}
.withdraw {
  color: red;
}
.credit {
  color: green;
}

.expenses_details {
  padding: 20px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin: 15px 0px;
}

.expense_cont {
  display: grid;
  grid-template-columns: 33% 35% 33%;
}

.expenses_grids {
  display: flex;
  gap: 12px;
  align-items: center;
}

.cashbook_title {
  font-size: 16px !important;
}
.cashbook_body {
  font-size: 14px !important;
  font-family: "Nunito Sans", sans-serif !important;
  font-style: normal;
}

@media screen and (max-width: 1063px) and (min-width: 980px) {
  .cashbook_title {
    font-size: 14px !important;
  }
  .cashbook_body {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 979px) {
  .expense_cont {
    display: block;
  }
}
