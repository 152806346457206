.file-input {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.file-input input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-input-label {
  display: inline-block;
  padding: 8px 12px;
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin: 15px;
}

.file-input-label:hover {
  background-color: #e0e0e0;
}
