@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
.home {
  display: flex;
}

.imp {
  color: red;
}

.graphcentered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.react-datepicker {
  z-index: 5 !important;
}

.subcardsview {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
}

@media screen and (max-width: 1024px) {
  .subcardsview {
    display: block;
  }
}
.gridingcards {
  padding: 12px;
}
.fade {
  opacity: 0.2;
}
.vis {
  opacity: 1;
}

.subcards {
  padding: 10px;
  padding-bottom: 3px !important;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  cursor: pointer;
}

.trans {
  border-radius: 20px !important;
}

@media screen and (max-width: 1170px) and (min-width: 880px) {
  .trans {
    font-size: 12px !important;
    padding: 6px 12px !important;
  }
}

@media screen and (max-width: 879px) {
  .trans {
    font-size: 8px !important;
    padding: 2px 6px !important;
    /* min-width: 100% !important; */
  }
}

.settled {
  background-color: green !important;
}
.failed {
  background-color: red !important;
}

.graph_headings {
  font-size: 16px;
  text-align: center;
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
  .graph_headings {
    font-size: 12px;
  }
}

.mobibutton {
  font-size: 12px !important;
  padding: 4px 6px !important;
}

.server_graphs {
  width: 60%;
  padding-right: 15px;
  padding-left: 10px;
  padding-top: 10px;
}

@media screen and (max-width: 1000px) {
  .server_graphs {
    width: 100% !important;
  }
}

.subcards:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.card__title {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: bold !important;
  margin: 2px !important;
  font-size: 13px !important;
  letter-spacing: 0px !important;
}
.card__title1 {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: bold !important;
  margin: 2px !important;
  font-size: 12px !important;
  letter-spacing: 0px !important;
}
.card__value {
  font-family: "Fredoka One", cursive !important;
  font-size: 16px !important;
  margin: 2px !important;
}

@media screen and (max-width: 796px) {
  .card__title {
    font-size: 10px !important;
  }
  .card__value {
    font-size: 12px !important;
  }
  .graph_headings {
    font-size: 14px !important;
  }
  .graph_tooltips {
    font-size: 8px !important;
  }
  .recharts-legend-item {
    font-size: 12px !important;
  }
  .recharts-cartesian-axis-tick {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 750px) {
  .scrolling {
    display: block;
    height: 100vh;
    overflow: scroll;
  }
  .second_screen {
    overflow: hidden !important;
    width: 100% !important;
    height: 100% !important;
  }
  .home {
    display: block;
  }
}

@media (max-width: 1600px) {
  .subcards {
    text-align: center;
  }
}

@media screen and (max-width: 1150px) and (min-width: 1004px) {
  .card__title1 {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1003px) and (min-width: 896px) {
  .card__title1 {
    font-size: 8px !important;
  }
}

@media screen and (max-width: 895px) {
  .card__title1 {
    font-size: 5px !important;
  }
}
