.calendarComp,
.calendar-clock-container {
  height: 40px;
  font-size: medium;
  font-family: "Comfortaa";
  border-radius: 15px !important;
  transform: none !important;
}

.react-datepicker__input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.react-datetime-picker__wrapper {
  border-radius: 15px;
  transform: initial;
}
