.link {
  color: inherit !important;
}

@media screen and (max-width: 750px) {
  #header {
    display: none;
  }
}

#header .pro-sidebar {
  height: 100vh;
}

.project-categories {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
}
#header .closemenu {
  color: var(--nav-item-color, rgba(255, 255, 255, 0.8));

  float: right;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
}
.sidebar-footer {
  height: 50px;
}
.menu-driven {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#header .pro-sidebar {
  width: 100%;
  /* min-width: 100%; */
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar-inner {
  background-color: #051e34;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: var(--nav-item-color, rgba(255, 255, 255, 0.8));
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: var(--nav-item-color, rgba(255, 255, 255, 0.8));

  margin: 10px 0px;
  font-weight: bold;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: rgba(71, 98, 130, 0.2);
}

#header .logo {
  padding: 20px;
}
