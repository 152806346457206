.couponbody {
  font-family: "Nunito Sans", sans-serif !important;
  font-style: normal;
  font-size: 15px !important;
}
.coupontext {
  font-family: "Suez One", sans-serif !important;
  font-style: bold;
  font-size: 18px !important;
}
.coupontitle {
  font-style: bolder !important;
  font-family: "Nunito Sans", sans-serif !important;

  font-size: 17px !important;
}

.remove_margin {
  margin: 5px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  overflow-y: initial !important;
}

.couponfirsts {
  display: flex;
  text-align: center;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.couponfirstsgrid {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: " a b ";
  text-align: center;
}

.grida {
  display: flex;
  align-items: center;
  gap: 10px;
  grid-area: a;
  justify-content: center;
}

.gridb {
  display: flex;
  align-items: center;
  gap: 10px;
  grid-area: b;
  justify-content: center;
}

@media screen and (max-width: 1109px) and (min-width: 750px) {
  .grida {
    display: block;
  }
  .gridb {
    display: block;
  }
}

@media screen and (max-width: 750px) {
  .coupontext {
    font-size: 14px !important;
  }
  .coupontitle,
  .couponbody {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 650px) and (min-width: 430px) {
  .grida {
    display: block;
  }
  .gridb {
    display: block;
  }
}

@media screen and (max-width: 429px) {
  .couponfirstsgrid {
    display: block;
  }
}
