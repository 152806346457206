/* CourseDetails.css */
.course-details-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.speaking-details-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.detail-row {
  display: flex;
  /* align-items: center; */
  padding: 10px;
  flex: 1 1 calc(50% - 20px);
  /* Two columns on larger screens with spacing */
  gap: 20px;
  /* Adds spacing between items */
  flex-wrap: wrap;
}

.detail-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  min-width: 150px !important;
  margin-right: 10px;
}

.detail-body {
  font-size: 16px !important;
  color: #333 !important;
  flex: 1;
}

@media screen and (max-width: 768px) {
  .detail-row {
    display: block;
    /* Full width on smaller screens */
  }
}

/* PDFViewer.css */

.pdf-container {
  width: 100%;
  height: 75vh;
  /* Set the initial height to full viewport height */
  overflow: hidden;
  /* Hide any overflow content beyond the viewport */

  /* Optional: Add additional styles or center the viewer if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* Use media queries to adjust the height based on screen size */
@media (max-width: 768px) {
  .pdf-container {
    height: 80vh;
    /* Adjust the height for smaller screens (e.g., mobile devices) */
  }
}

.category_writing {
  display: flex;
  gap: 10px;
}

@media (max-width: 560px) {
  .category_writing {
    display: block !important;
  }
}

.newline {
  white-space: pre-line;
}