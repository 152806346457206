/* Default styles for .profileImage */
.profileImage {
  width: 300px;
  height: 300px;
  object-fit: contain;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

/* Default styles */
.buttonContainer {
  position: unset;
  text-align: center;
  margin-bottom: 10px;
}

/* Responsive styles */
/* @media screen and (max-width: 768px) { */

/* Adjust the top and left values for smaller screens */
/* .buttonContainer {
       
    }
} */

/* Add more media queries for other screen sizes if necessary */

/* Media query for smaller devices (e.g., smartphones) */
@media screen and (max-width: 767px) {
  .profileImage {
    width: 200px;
    height: 200px;
  }
}

/* Media query for medium-sized devices (e.g., tablets) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .profileImage {
    width: 250px;
    height: 250px;
  }
}

/* Media query for larger devices (e.g., laptops and desktops) */
@media screen and (min-width: 1024px) {
  .profileImage {
    width: 300px;
    height: 300px;
  }
}

.profileContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
