.user-activity-container {

    height: 24rem; /* h-96 */
    padding: 1rem; /* p-4 */
    display: flex;
    flex-direction: column;
    /* border: 2px solid #fca5a5; */
  }
  
  .user-activity-header {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
  }
  
  .user-activity-title {
    font-size: 1.25rem; /* text-xl */
  }
  
  .user-activity-date {
    font-weight: 600;
  }
  
  .user-activity-chart {
    width: 100%;
    height: 100%;
  }

  
  