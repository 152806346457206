.table_comp {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.table_comp tr {
  margin-bottom: 15px;
  border-bottom: 1px solid black;
}

.tablerow {
  border-bottom: 0 !important;
}

.table_comp th,
.table_comp td {
  display: table-cell;
  padding: 12px;
}

@media screen and (max-width: 1170px) and (min-width: 880px) {
  .table_comp tr {
    margin-bottom: 8px;
    font-size: 12px;
  }
  .table_comp th,
  .table_comp td {
    padding: 5px;
  }
}

@media screen and (max-width: 879px) {
  .table_comp tr {
    font-size: 10px;
  }
  .table_comp th,
  .table_comp td {
    padding: 2px;
  }
}

/* Server screen table */

.server_table_comp {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.server_table_comp table {
  transform: initial !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50px;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.server_table_comp table,
.server_table_comp th,
.server_table_comp td {
  border: 1px solid black;
}

.server_table_comp tr {
  margin-bottom: 15px;
}

.server_table_comp th,
.server_table_comp td {
  display: table-cell;
  padding: 12px;
}

@media screen and (max-width: 1170px) and (min-width: 880px) {
  .server_table_comp tr {
    margin-bottom: 8px;
    font-size: 12px;
  }
  .server_table_comp th,
  td {
    padding: 5px;
  }
}

@media screen and (max-width: 879px) and (min-width: 460px) {
  .server_table_comp tr {
    font-size: 10px;
  }
  .server_table_comp th,
  .server_table_comp td {
    padding: 2px;
  }
}

/* Users Table Comp */

.users_table_comp {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.users_table_comp table {
  transform: initial !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50px;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.users_table_comp th,
.users_table_comp td {
  border-bottom: 1px solid black;
}

.users_table_comp tr {
  margin-bottom: 15px;
}

.users_table_comp th,
.users_table_comp td {
  display: table-cell;
  padding: 18px;
}

@media screen and (max-width: 1170px) and (min-width: 880px) {
  .users_table_comp tr {
    margin-bottom: 8px;
    font-size: 12px;
  }
  .users_table_comp th,
  .users_table_comp td {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 879px) {
  .users_table_comp tr {
    font-size: 10px;
  }
  .users_table_comp th,
  .users_table_comp td {
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media screen and (max-width: 459px) {
  .users_table_comp tr {
    font-size: 7px;
  }
  .users_table_comp th,
  .users_table_comp td {
    padding-left: 1px;
    padding-right: 1px;
  }
}
