.coursetitle {
  display: flex;
  gap: 10px;
  text-align: center;
  flex-wrap: wrap;

  align-items: center;
}

.coursegrid {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-areas: "second first";
  align-items: center;
  gap: 10px;
}

.coursedetails {
  grid-area: first;
}

.courseimg {
  grid-area: second;
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}

.ThumbnailImg {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}

@media screen and (max-width: 1000px) {
  .coursegrid {
    display: block !important;
    text-align: center;
  }

  .courseimg {
    text-align: center;
  }
}
