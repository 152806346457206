.Search__Container {
  padding: 10px;
}

.Search__Input {
  display: flex;
  border: 1px solid black;
  border-radius: 50px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.Search__Input input {
  border: 0;
  width: 95%;
}
.Search__Input input:focus {
  outline: none;
}

@media screen and (max-width: 750px) {
  .Search__Input {
    width: 95%;
    padding: 12px;
  }
}
