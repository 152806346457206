:root {
  --white: #fff;
  --google-blue: #4285f4;
  --button-active-blue: #1669f2;
}
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,100,700,900);

.google-btn {
  width: 184px;
  height: 42px;
  background-color: var(--google-blue);
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.google-btn:hover {
  box-shadow: 0 0 6px var(--google-blue);
}
.google-btn:active {
  background: var(--button-active-blue);
}
.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: var(--white);
}
.google-icon {
  margin-top: 11px;

  width: 18px;
  height: 18px;
}
.google-icon-text {
  float: right;
  margin: 11px 11px 0 0;
  color: var(--white);
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}

.centerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 85vh;
}

.heading {
  font-size: 8.8em;
  margin-bottom: 0 !important;
  font-weight: 800;
  width: 100%;

  text-align: center;

  color: #55a039;
}

.secondheading {
  font-size: 24px;
}

@media screen and (max-width: 680px) {
  .heading {
    font-size: 3.4em !important;
  }
  .secondheading {
    font-size: 15px !important;
  }
}

.bar {
  background-color: #e1f8d4;
  min-width: 300px;
  min-height: 5px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.bar::after {
  content: "";
  min-height: 5px;
  position: absolute;
  background: #55a039;
  transform: translateX(-100%);
  animation: animate 3s infinite;
}
.bar::before {
  content: "";
  min-height: 5px;
  position: absolute;
  background: #55a039;
  transform: translateX(-100%);
  animation: animate 3s ease-out infinite;
  animation-delay: 1s;
  z-index: 5;
}

.by {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-family: "roboto";
  font-size: 20px;
}
@keyframes animate {
  0% {
    transform: translateX(-100%);
    min-width: 10px;
  }
  100% {
    transform: translateX(300%);
    min-width: 400px;
  }
}
