.link {
  color: inherit !important;
}
#header_mob .pro-sidebar {
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  z-index: 2;
}
.hide_mob {
  display: none;
}
#header_mob {
  transform: none !important;
  -webkit-transform: none !important;
}

.project-categories1 {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
}
#header_mob .closemenu {
  color: var(--nav-item-color, rgba(255, 255, 255, 0.8));

  float: right;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 15px;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
}

.menu-driven {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#header_mob .pro-sidebar {
  width: 100%;
  /* min-width: 100%; */
}
#header_mob .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header_mob .pro-sidebar-inner {
  background-color: #051e34;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header_mob .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 15px;
  padding: 0 20px;
  color: var(--nav-item-color, rgba(255, 255, 255, 0.8));
  font-weight: bold;
}
#header_mob .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header_mob .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: var(--nav-item-color, rgba(255, 255, 255, 0.8));
  font-size: 12px;
  margin: 10px 0px;
  font-weight: bold;
}
#header_mob
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}
#header_mob
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: rgba(71, 98, 130, 0.2);
}

#header_mob .logo {
  padding: 12px;
}
